import React from "react"
import Layout from "../components/Layout"

export default function datenschutz() {
  return (
    <Layout>
      <div className="container main-container">
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span style={{ position: "relative", zIndex: -1895825408 }}>
            <span
              style={{
                left: "364px",
                position: "absolute",
                top: "-20px",
                width: "85px",
                height: "84px",
              }}
            >
            </span>
          </span>
          <b>
            <span
              style={{ fontSize: "20.0pt",  }}
            >
              Datenschutzordnung TV Lahr&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>Präambel</span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Der TV Lahr von 1846 e.V. verarbeitet in vielfacher Weise
            automatisiert personenbezogene Daten (z.B. im Rahmen der
            Vereinsverwaltung, der Organisation des Sportbetriebs, der{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Öffentlichkeitsarbeit des Vereins). Um die Vorgaben der
            EU-Datenschutz-Grundverordnung und des Bundesdatenschutzgesetzes zu
            erfüllen, Datenschutzverstöße zu vermeiden und einen einheitlichen
            Umgang mit personenbezogenen Daten innerhalb des Vereins zu
            gewährleisten, gibt sich der Verein die nachfolgende
            Datenschutzordnung.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 1 Allgemeines{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Der Verein verarbeitet personenbezogene Daten u.a. von Mitgliedern,
            Teilnehmerinnen und Teilnehmern am Sport- und Kursbetrieb und
            Mitarbeiterinnen und Mitarbeitern sowohl automatisiert in
            EDV-Anlagen als auch nicht automatisiert in einem Dateisystem, z.B.
            in Form von ausgedruckten Listen. Darüber hinaus werden
            personenbezogene Daten im Internet veröffentlicht und an Dritte
            weitergeleitet oder Dritten offengelegt soweit dies für die
            Vereinsarbeit erforderlich ist. In all diesen Fällen ist die
            EU-Datenschutz-Grundverordnung, das{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Bundesdatenschutzgesetz und diese Datenschutzordnung durch alle
            Personen im Verein, die personenbezogene Daten verarbeiten, zu
            beachten.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 2 Verarbeitung personenbezogener Daten der Mitglieder{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            1. Der Verein verarbeitet die Daten unterschiedlicher Kategorien von
            Personen. Jede Kategorie von betroffenen Personen wird im
            Verzeichnis der Verarbeitungstätigkeiten aufgeführt.
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            2. Im Rahmen des Mitgliedschaftsverhältnisses verarbeitet der Verein
            insbesondere die folgenden Daten der Mitglieder: Geschlecht,
            Vorname, Nachname, Anschrift (Straße, Hausnummer, Postleitzahl,
            Ort), Geburtsdatum, Datum des Vereinsbeitritts, Abteilungs- und ggf.
            Mannschaftszugehörigkeit, Bankverbindung, ggf. die Namen und
            Kontaktdaten der gesetzlichen Vertreter, Telefonnummern und
            E-Mail-Adressen, ggf. Funktion im Verein, ggf. Haushalts- und
            Familienzugehörigkeit bei Zuordnung zum Familienbeitrag.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            3. Im Rahmen der Zugehörigkeit zu den Sportverbänden, deren
            Sportarten im Verein betrieben werden, werden personenbezogene Daten
            der Mitglieder an diese weitergeleitet, soweit die Mitglieder eine
            Berechtigung zur Teilnahme am Wettkampfbetrieb der Verbände
            beantragen (z.B. Startpass, Spielerpass, Lizenz) und an solchen
            Veranstaltungen teilnehmen. Weiterhin werden personenbezogene Daten
            der Mitglieder zum Zwecke von Ehrungen an die Stadtverwaltung
            weitergegeben.
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 3 Datenverarbeitung im Rahmen der Öffentlichkeitsarbeit{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            1. Im Rahmen der Öffentlichkeitsarbeit über Vereinsaktivitäten
            werden personenbezogene Daten in der Vereinszeitung, in
            Internetauftritten und Sozialen Medien veröffentlicht und an die
            Presse weitergegeben.&nbsp;{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            2. Hierzu zählen insbesondere die Daten, die aus allgemein
            zugänglichen Quellen stammen: Teilnehmer an sportlichen
            Veranstaltungen, Mannschaftsaufstellung, Ergebnisse, Torschützen,
            Alter oder Geburtsjahrgang.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            3. Die Veröffentlichung von Fotos und Videos, die außerhalb
            öffentlicher Veranstaltungen gemacht wurden, erfolgt ausschließlich
            auf Grundlage einer Einwilligung der abgebildeten Personen.&nbsp;{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            4. Auf der Internetseite des Vereins werden die Daten der Mitglieder
            des Vorstands, der Abteilungsleiterinnen und Abteilungsleiter und
            der Übungsleiterinnen und Übungsleiter mit Vorname, Nachname,
            Funktion veröffentlicht.&nbsp;{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 4 Zuständigkeiten für die Datenverarbeitung im Verein{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Verantwortlich für die Einhaltung der datenschutzrechtlichen
            Vorgaben ist der geschäftsführende Vorstand nach § 26 BGB.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Die Geschäftsstelle stellt sicher, dass Verzeichnisse der
            Verarbeitungstätigkeiten nach{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Art. 30 DSGVO geführt und die Informationspflichten nach Art. 13 und
            14 DSGVO erfüllt werden. Sie ist für die Beantwortung von
            Auskunftsverlangen von betroffenen Personen zuständig.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 5 Verwendung und Herausgabe von Mitgliederdaten und -listen{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            1. Listen von Mitgliedern oder Teilnehmern werden den jeweiligen
            Mitarbeiterinnen und Mitarbeitern im Verein (z.B.
            Vorstandsmitgliedern, Abteilungsleitern, Übungsleitern) insofern zur
            Verfügung gestellt, wie es die jeweilige Aufgabenstellung erfordert.
            Beim Umfang der dabei verwendeten personenbezogenen Daten ist das
            Gebot der Datensparsamkeit zu beachten.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            2. Personenbezogene Daten von Mitgliedern dürfen an andere
            Vereinsmitglieder nur herausgegeben werden, wenn die Einwilligung
            der betroffenen Person vorliegt. Die Nutzung von Teilnehmerlisten,
            in die sich die Teilnehmer von Versammlungen und anderen
            Veranstaltungen zum Beispiel zum Nachweis der Anwesenheit eintragen,
            gilt nicht als eine solche Herausgabe.&nbsp;{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 6 Kommunikation per E-Mail{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{ marginLeft: "0cm", textAlign: "justify" }}
        >
          <span>
            1. Für die Kommunikation per E-Mail richtet der Verein einen
            vereinseigenen E-Mail-Account ein, der im Rahmen der vereinsinternen
            Kommunikation ausschließlich zu nutzen ist.&nbsp;{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            2. Beim Versand von E-Mails an eine Vielzahl von Personen, die nicht
            in einem ständigen Kontakt per E-Mail untereinanderstehen und/oder
            deren private E-Mail-Accounts verwendet werden, sind die
            E-Mail-Adressen als „Bcc“ zu versenden.&nbsp;{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 7 Verpflichtung auf die Vertraulichkeit{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Alle Mitarbeiterinnen und Mitarbeiter im Verein, die Umgang mit
            personenbezogenen Daten haben (z.B. Mitglieder des Vorstands,
            Abteilungsleiterinnen und Abteilungsleiter, Übungsleiterinnen und
            Übungsleiter, Mitarbeiter der Geschäftsstelle), sind auf den
            vertraulichen Umgang mit personenbezogenen Daten zu
            verpflichten.&nbsp;{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <b>
          <span
            style={{ fontSize: "11.0pt",  }}
          >
            <br clear="all" style={{ pageBreakBefore: "always" }} />
          </span>
        </b>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>&nbsp;</span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 8 Datenschutzbeauftragter{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Da im Verein in der Regel weniger als 10 Personen ständig mit der
            automatisierten Verarbeitung personenbezogener Daten beschäftigt
            sind, hat der Verein keinen Datenschutzbeauftragten zu benennen.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 9 Einrichtung und Unterhaltung von Internetauftritten{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            1. Der Verein unterhält zentrale Auftritte für den Gesamtverein. Die
            Einrichtung und Unterhaltung von Auftritten im Internet obliegt der
            Geschäftsstelle. Änderungen dürfen ausschließlich durch die
            Geschäftsstelle und den Administrator vorgenommen werden.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            2. Die Geschäftsstelle ist für die Einhaltung der
            Datenschutzbestimmungen im Zusammenhang mit Online-Auftritten
            verantwortlich.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            3. Abteilungen, Gruppen und Mannschaften bedürfen für die
            Einrichtung eigener Internetauftritte (z.B. Homepage, Facebook,
            Twitter) der ausdrücklichen Genehmigung des geschäftsführenden
            Vorstandes. Für den Betrieb eines Internetauftritts haben die
            Abteilungen, Gruppen und Mannschaften Verantwortliche zu benennen,
            denen gegenüber der geschäftsführende Vorstand weisungsbefugt ist.
            Bei Verstößen gegen datenschutzrechtliche Vorgaben und Missachtung
            von Weisungen des geschäftsführenden Vorstandes, kann der
            geschäftsführende Vorstand nach § 26 BGB die Genehmigung für den
            Betrieb eines Internetauftritts widerrufen. Die Entscheidung des
            geschäftsführenden Vorstands nach § 26 BGB ist unanfechtbar.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 10 Verstöße gegen datenschutzrechtliche Vorgaben und diese
              Ordnung{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            1. Alle Mitarbeiterinnen und Mitarbeiter des Vereins dürfen nur im
            Rahmen ihrer jeweiligen Befugnisse Daten verarbeiten. Eine
            eigenmächtige Datenerhebung, -nutzung oder{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            –weitergabe ist untersagt.&nbsp;{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            2. Verstöße gegen allgemeine datenschutzrechtliche Vorgaben und
            insbesondere gegen diese Datenschutzordnung können gemäß den
            Sanktionsmitteln, wie sie in der Satzung vorgesehen sind, geahndet
            werden.&nbsp;{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span>
              § 11 Inkrafttreten{" "}
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>
            Diese Datenschutzordnung wurde durch den Gesamtvorstand des Vereins
            am 16.10.2020 beschlossen und tritt mit Veröffentlichung auf der
            Homepage des Vereins in Kraft.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal">
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal">
          <span>&nbsp;</span>
        </p>
        <p className="MsoNormal">
          <span>&nbsp;</span>
        </p>
      </div>
    </Layout>
  )
}
